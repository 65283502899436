import Icon, { IconSlugType } from "components/Icon";

import { SERVICE_MAP, ServiceType, SERVICE_MAP_KEYS } from "./data";

export type ServiceKeyType = keyof typeof SERVICE_MAP_KEYS;

interface ServiceProps {
  service: ServiceKeyType[];
}

const Service = ({ service: services }: ServiceProps) => {
  return (
    <div className=" md:px-6; mx-auto  max-w-7xl px-4">
      <div className="flex flex-col md:grid md:grid-cols-12 md:gap-x-16 ">
        {services.map((serviceType) => {
          const key = SERVICE_MAP_KEYS[serviceType];
          const service: ServiceType = SERVICE_MAP[key];
          return (
            <div className="md:col-span-6" key={serviceType}>
              <h3 className="mb-2 mt-8 flex items-center space-x-2">
                {service.icon && (
                  <span className="h-[3.375rem] w-[3.375rem] lg:h-[4.5rem] lg:w-[4.5rem]">
                    <Icon slug={service.icon as IconSlugType} className="h-full w-full" />
                  </span>
                )}
                <span>{service.title}</span>
              </h3>
              {service.description && <p className="mt-4 lg:mt-6">{service.description}</p>}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Service;
