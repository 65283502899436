import Award, { AwardProps } from "components/Award";

interface AwardGridProps {
  awards: AwardProps[];
}

const AwardGrid = ({ awards }: AwardGridProps) => {
  return (
    <>
      <ul className="m-0 flex flex-wrap items-center justify-center p-0">
        {awards.map((award) => (
          <li
            className="md:m-0 mr-4 w-1/2 p-0 before:hidden md:mr-6 md:w-1/3 lg:w-1/6"
            key={award.title}
          >
            <Award {...award} />
          </li>
        ))}
      </ul>
    </>
  );
};

export default AwardGrid;
