import { ComponentProps, ElementType, useMemo } from "react";

import { twJoin, twMerge } from "tailwind-merge";

import AngleSpacer from "components/Angle";
import Block from "components/Block";
import { ButtonColorScheme, ButtonLink, LegacyButtonSize } from "components/Button";
import Hero from "components/cms/Hero";
import DynamicComponent, { SectionType } from "components/DynamicComponent";
import DefaultLayout from "components/Layouts/Default";
import Nav from "components/Nav";
import SEO from "components/SEO";

import { removeCmsRichTextWrapper } from "services/utils";

type BlockType = {
  id: string;
  value: any;
  type: SectionType;
};

export interface PageProps {
  page: {
    title: string;
    meta: any;
    blocks: BlockType[];
  };
}

interface BodyProps {
  richText: string;
  className: string;
}

const buttonThemeCMSMap: Record<string, ButtonColorScheme> = {
  accent: "light",
  dark: "light",
  light: "accent",
};

const Column = ({ richText, className }: BodyProps) => {
  const strippedRichText = useMemo(() => removeCmsRichTextWrapper(richText), [richText]);

  return (
    <div className={className}>
      <article
        className="prose prose-h2:mt-12 prose-h3:mt-10 prose-p:my-[0.5em] [&>:first-child]:mt-0"
        dangerouslySetInnerHTML={{ __html: strippedRichText }}
      />
    </div>
  );
};

const Body = ({ columns }: { columns: any }) => {
  return (
    <div className="space-y-6 lg:space-y-12">
      {columns &&
        columns.length &&
        columns.map(
          ({ value: { rich_text, left_column, middle_column, right_column }, id }: any) => {
            const leftColumnText = left_column?.rich_text;
            const rightColumnText = right_column?.rich_text;
            const middleColumnText = middle_column?.rich_text;

            const singleColumnBlock = !rightColumnText && !middleColumnText;
            const doubleColumnBlock = leftColumnText && !middleColumnText && rightColumnText;
            const tripleColumnBlock = leftColumnText && middleColumnText && rightColumnText;

            return (
              <div
                className="flex grid-cols-12 flex-col gap-x-8 gap-y-4 md:grid lg:gap-y-8"
                key={id}
              >
                {(rich_text || leftColumnText) && (
                  <Column
                    richText={rich_text || leftColumnText}
                    className={twMerge(
                      doubleColumnBlock && "col-span-12 md:col-span-6",
                      tripleColumnBlock && "col-span-12 md:col-span-6 lg:col-span-4",
                      singleColumnBlock && "col-span-12"
                    )}
                  />
                )}
                {middleColumnText && (
                  <Column
                    richText={middleColumnText}
                    className="col-span-12 md:col-span-6 lg:col-span-4"
                  />
                )}
                {rightColumnText && (
                  <Column
                    richText={rightColumnText}
                    className={
                      middleColumnText ? "col-span-12 lg:col-span-4" : "col-span-12 md:col-span-6"
                    }
                  />
                )}
              </div>
            );
          }
        )}
    </div>
  );
};

const PageContent = ({ page }: PageProps) => {
  const { blocks, title, meta } = page;

  const firstBlockTheme = blocks[0].value.block_theme?.toLowerCase();

  const navVariant: ComponentProps<typeof Nav>["variant"] = useMemo(() => {
    switch (firstBlockTheme) {
      case "light":
        return "black-on-white";
      case "accent":
        return "white-on-red";
      case "dark":
        return "white-on-black";
      default:
        return "black-on-white";
    }
  }, [firstBlockTheme]);

  return (
    <DefaultLayout navVariant={navVariant}>
      <SEO title={title} description={meta.search_description} />
      {blocks &&
        blocks.map((section, index) => {
          const {
            value: { block_theme, heading: header, cta, body, columns, ...rest },
            id,
            type,
          } = section;
          const parsedBody = removeCmsRichTextWrapper(body);

          const theme = block_theme.toLowerCase();

          const hasSameThemeAsPrevious =
            blocks[index - 1]?.value.block_theme === block_theme &&
            blocks[index - 1]?.type !== "projects";

          const isFirstBlock = index === 0;
          const isSecondBlock = index === 1;

          if (type === "hero") {
            return (
              <Block theme={theme} key={id}>
                <Hero
                  heading={header}
                  ctaTheme={buttonThemeCMSMap[theme]}
                  cta={cta}
                  description={rest.hero_body}
                />
                {isFirstBlock && <AngleSpacer />}
              </Block>
            );
          } else {
            const { heading, size } = header || {};
            const HeadingTag = (size ? size.toLowerCase() : "h2") as ElementType;
            return (
              <Block
                theme={theme}
                key={id}
                fullWidth={type === "projects"}
                className={twMerge(
                  "prose max-w-none",
                  type !== "projects" && [
                    hasSameThemeAsPrevious
                      ? "pb-12 md:pb-20 lg:pb-[7.5rem] xl:pb-[8.125rem]"
                      : "py-12 md:py-20 lg:py-[7.5rem] xl:py-[8.125rem]",
                  ],
                  type === "body_content" && index === 0 && "mt-16",
                  isSecondBlock && "angle-mask"
                )}
              >
                {isSecondBlock && <AngleSpacer />}
                {heading && (
                  <HeadingTag
                    className={twJoin(
                      type === "projects" &&
                        "mx-auto w-full max-w-7xl px-4 md:px-6 xl:px-0 pt-12 lg:pt-16 text-center mx-auto",
                      type === "contact_form" && "text-center mx-auto",
                      "mb-6"
                    )}
                  >
                    {heading}
                  </HeadingTag>
                )}

                {/* This is for the body field under other widgets/blocks on wagtail, */}
                {parsedBody && (
                  <article
                    className={twJoin(
                      "mb-10",
                      ["projects", "contact_form"].includes(type) && "mx-auto text-center max-w-4xl"
                    )}
                    dangerouslySetInnerHTML={{ __html: removeCmsRichTextWrapper(parsedBody) }}
                  />
                )}

                {/* This is for the "Body Content" widget on wagtail, this widget implements content in columns */}
                {columns && <Body columns={columns} />}
                <DynamicComponent section={section} />

                {cta && cta.text && (
                  <ButtonLink
                    label={cta.text}
                    size={LegacyButtonSize[cta.size]}
                    href={cta.link}
                    colorScheme={buttonThemeCMSMap[theme]}
                    className="mt-6 md:mt-10 lg:mt-16"
                  />
                )}
              </Block>
            );
          }
        })}
    </DefaultLayout>
  );
};

export default PageContent;
