export type Abbrevation = {
  short: string;
  long: string;
};
const abbr = (short: string, long: string): Abbrevation => ({
  short,
  long,
});

export type ServiceType = {
  title: string;
  icon?: string;
  description?: string;
  list?: Array<string | Abbrevation>;
};

export const SERVICE_MAP_KEYS = {
  APP_DEVELOPMENT: "mobile",
  DESIGN_UI_UX: "design",
  WEB_DEVELOPMENT: "web",
  ENTERPRISE_APP_DEVELOPMENT: "enterprise",
  NEXT_GEN_SPECIALITIES: "next-gen",
  MARTKETING: "marketing",
  GROWTH: "growth",
};

export const SERVICE_MAP: Record<string, ServiceType> = {
  "app-development": {
    title: "App Development",
    icon: "idea",
  },
  design: {
    icon: "design",
    title: "Design & UI/UX",
    description:
      "The best designs instill confidence in your product and brand. They inspire users to download, engage, and evangelize. Our digitally native designers create uniquely beautiful and functional work grounded in results-oriented best\xa0practices.",
    list: [
      "Logo & Branding",
      "User Experience",
      "Information Architecture",
      "Prototyping",
      "Mobile & Web\xa0Design",
    ],
  },
  "digital-transformation": {
    title: "Digital Transformation",
  },
  enterprise: {
    icon: "backend",
    title: "Enterprise Application Development",
    description:
      "When off-the-shelf ERP applications just don’t cut it, our custom development capabilities mean we can produce bespoke solutions that have the horsepower, firepower, and that solve real\xa0problems.",
    list: [
      abbr("CRM", "Customer Relationship Management"),
      abbr("POS", "Point of Sale"),
      abbr("CMS", "Content Management System"),
      abbr("ERP", "Enterprise Resource Planning"),
      abbr("CDP", "Customer Data Platform"),
    ],
  },
  growth: {
    title: "Growth Marketing",
    icon: "project-management",
    description:
      "Growth maximizes the ROI on digital products. Our close partnerships with a range of vendors enable us to optimize user experiences, increase retention, drive revenue, and maximize customer lifetime\xa0value.",
    list: [
      "Marketing Automation & Lifecycle Campaigns",
      "Analytics & Insights",
      "Central Data Management",
      "Attribution Tracking & Optimizations",
      "A/B Testing & Workflow Optimizations",
    ],
  },
  marketing: {
    icon: "marketing",
    title: "Digital Marketing",
    description:
      "The strongest marketing operations are tightly integrated with product teams, enabling seamless coordination between feature development, integration of marketing tools and services, and building strong cycles of users who download and then continue using a\xa0product.",
    list: [
      abbr("SEO & ASO", "Search Engine Optimization & App Store Optimization"),
      "Paid Media Planning & Implementation",
      "Campaign ROI Monitoring & Reporting",
    ],
  },
  mobile: {
    icon: "mobile",
    title: "App Development",
    description:
      "We’ve won App of the Year from Apple and our products have processed over $10 billion in transactions, so it’s no surprise we’re consistently ranked as the world’s top app developer. That’s probably also why we’re the go-to partner for unicorn startups like Warby Parker and Compass as well as Fortune 500 companies like Verizon and\xa0Google.",
    list: [
      "iOS Development",
      "Android Development",
      "React Native Development",
      "Flutter Development",
    ],
  },
  "next-gen": {
    icon: "dapp",
    title: "Next-Gen Tech Specialties",
    description:
      "Gimmick or next big thing? Sometimes it’s a fine line. We love experimenting with emerging tech, which ensures our stack is future-proofed and when something moves from toy to transformative, we have a nice head\xa0start.",
    list: [
      abbr("AR/VR", "Augmented Reality/Virtual Reality"),
      "Connected Hardware",
      "Blockchain/dApps",
      "Chatbots",
      "Alexa/Google Home",
    ],
  },
  web: {
    icon: "web",
    title: "Web Development",
    description:
      "Our experience with small screens comes in handy in a world where the majority of web traffic is on mobile. Our web team builds responsive sites that elegantly work across desktop and mobile, leveraging the same robust backend services we use for our\xa0apps.",
    list: ["React, Vue.js", "Python/Django", "Node", "E-commerce Web Development"],
  },
};
