import { useMemo } from "react";

import Link from "next/link";

import Block from "components/Block";
import DiagonalSection from "components/DiagonalSection";
import DefaultLayout from "components/Layouts/Default";

interface PageErrorProps {
  errorCode?: "403" | "404" | "500";
}

const links = [
  { label: "Go to our homepage", url: "/" },
  { label: "Check out our projects", url: "/projects" },
  { label: "Peruse our blog", url: "/blog" },
];

const PageError = ({ errorCode }: PageErrorProps) => {
  const { title, description } = useMemo(() => {
    switch (errorCode) {
      case "404":
        return {
          title: "404: Page not found",
          description:
            "Sorry this page isn't available. The link you followed may be broken, or the page may have been removed.",
        };
      case "403":
        return {
          title: "403: Forbidden",
          description:
            "Sorry this page isn't available. You do not have the necessary permissions to access this page.",
        };
      default:
        return { title: "Something went wrong", description: "Sorry this page isn't available." };
    }
  }, [errorCode]);

  return (
    <DefaultLayout navVariant="white-on-red" hideFooter>
      <DiagonalSection bottom="up">
        <Block
          as="section"
          theme="accent"
          className="h-full flex flex-col justify-center py-20 min-h-screen"
        >
          <h1 className="super-h1 max-w-[10em] xl:max-w-[16em]">
            This page is having an existential crisis
          </h1>

          <h2 className="h5">{title}</h2>

          <p className="my-7">{description}</p>

          <ul className="list-none [&>li]:body-lg">
            {links.map((link) => (
              <li key={link.label}>
                <Link href={link.url} className="link-arrow-right underline hover:!opacity-100">
                  {link.label}
                </Link>
              </li>
            ))}
          </ul>
        </Block>
      </DiagonalSection>
    </DefaultLayout>
  );
};

export default PageError;
