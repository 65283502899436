import { twMerge } from "tailwind-merge";

type DiagonalSectionDirection = "up" | "down";

interface DiagonalSectionProps {
  top?: DiagonalSectionDirection;
  bottom?: DiagonalSectionDirection;
  className?: string;
  children: React.ReactNode;
}

const DiagonalSection = ({ top, bottom, className, children }: DiagonalSectionProps) => {
  return (
    <>
      <section
        className={twMerge(
          top === "down" && "diagonal-section-top-down",
          top === "up" && "diagonal-section-top-up",
          bottom === "down" && "diagonal-section-bottom-down",
          bottom === "up" && "diagonal-section-bottom-up",
          className
        )}
      >
        {children}
      </section>
    </>
  );
};

export default DiagonalSection;
