export type Stat = {
  label: string;
  amount: string | number;
};
interface StatsProps {
  stats: Stat[];
}

const Stats = ({ stats }: StatsProps) => {
  return (
    <ul className="flex flex-col gap-y-6 md:grid md:grid-cols-12 md:flex-row md:gap-x-8 ">
      {stats.map(({ amount, label }, index) => (
        <li className="m-0 p-0 before:hidden md:col-span-6" key={index}>
          <span className="block font-display text-6xl italic -tracking-wider lg:inline lg:pr-6 lg:text-9xl">
            {amount}
          </span>
          <span className="block body-lg lg:inline lg:align-top">{label}</span>
        </li>
      ))}
    </ul>
  );
};

export default Stats;
