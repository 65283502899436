export const removeCmsRichTextWrapper = (content = "") =>
  content
    .replace(
      /<div class="rich-text"><html><head><\/head><body><div class="rich-text">(.*)<\/div><\/body><\/html><\/div>/,
      "$1"
    )
    .replace("<p></p>", "")
    .trim();

export const normalizeCharacters = (text: string) => {
  return text.normalize("NFD").replace(/\p{Diacritic}/gu, "");
};
