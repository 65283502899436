import CompanyLogo from "components/CompanyLogo";

interface ClientProps {
  companies: string[];
}

const Client = ({ companies }: ClientProps) => {
  return (
    <>
      {companies.length < 3 ? (
        <p>A minimum of 3 unique clients is required</p>
      ) : (
        <ul className="grid grid-cols-12 gap-x-6 lg:gap-y-12 px-0">
          {companies.map((company, index) => (
            <li
              className="m-0 flex items-center justify-center p-0 before:hidden col-span-6 sm:col-span-4 lg:col-span-3"
              key={index}
            >
              <span className="flex h-28 w-36 max-w-full items-center justify-center sm:h-32 sm:w-44 xl:h-36 xl:w-52 2xl:w-10/12 ">
                <CompanyLogo slug={company} className="h-full w-full" />
              </span>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default Client;
