import React, { HTMLAttributes, FC, ElementType } from "react";

import Image from "next/image";
import { StaticImageData } from "next/image";
import { twMerge } from "tailwind-merge";

import { BlockColorScheme } from "../types";

type TagType = "section" | "div" | "article" | "main" | "footer" | "span";
type SizeType = "cover" | "auto" | "grow" | "inherit";

export interface BackgroundImageProps {
  // Accounts for being passed a:
  // - string
  // - Image object
  // - SVG element
  src: string | StaticImageData | React.ElementType;

  fit?: string;
  position?: string;
  width?: string;
  horizontalAlign?: "left" | "center" | "right";
  verticalAlign?: "top" | "center" | "bottom";
  className?: string;
  id?: string;
}

const BackgroundImage = ({ src, width, horizontalAlign, className }: BackgroundImageProps) => {
  const SVG = typeof src === "function" ? (src as ElementType) : () => null;

  return (
    <div
      className="block-bg-image absolute bottom-0 right-0 top-0 w-full md:h-full"
      style={{
        width: width || "100%",
        left: horizontalAlign === "right" ? "auto" : 0,
      }}
    >
      <div className={twMerge("relative h-full w-full", className)}>
        {typeof src === "function" ? (
          <SVG className="h-full w-full" />
        ) : (
          <Image alt="" src={src} fill className="object-cover" sizes={"100vw"} />
        )}
      </div>
    </div>
  );
};

interface ComponentProps extends HTMLAttributes<HTMLOrSVGElement> {
  as?: TagType;
  backgroundImage?: BackgroundImageProps;
  backgroundColor?: string;
  children?: React.ReactNode;
  theme?: BlockColorScheme;
  size?: SizeType;
  className?: string;
  fullWidth?: boolean;
}

const colorSchemeMap = {
  white: "bg-white text-black",
  light: "bg-light text-black",
  accent:
    "bg-brand-500 text-white [&_li_a]:text-white [&_li_a:hover]:text-white [&_li_a:hover]:opacity-75",
  dark: "bg-dark text-white",
  black: "bg-black text-white",
} as Record<BlockColorScheme, string>;

const Block: FC<ComponentProps> = ({
  as: Tag = "div",
  backgroundImage,
  backgroundColor,
  className,
  children,
  fullWidth,
  id,
  theme = "light",
}) => {
  return (
    <Tag
      id={id}
      className={twMerge(colorSchemeMap[theme], className, "pointer-events-auto relative w-full")}
      style={{
        backgroundColor: backgroundColor,
      }}
    >
      {backgroundImage?.src && <BackgroundImage {...backgroundImage} />}
      <div className={fullWidth ? "w-full" : "constrained w-full"}>{children}</div>
    </Tag>
  );
};

export default Block;
