import React, { useMemo } from "react";

import { twMerge } from "tailwind-merge";

import { SizeType } from "../types";

type CitationType = {
  name: string;
  cite?: string;
  link?: string;
};

interface BlockquoteProps {
  citation?: string | CitationType | CitationType[];
  quote: string | string[];
  hasQuoteMarks?: boolean;
  size?: SizeType;
}

const sizeMap = {
  small: "max-w-xl",
  medium: "",
  large: "",
} as Record<SizeType, string>;

const quoteFontSizeMap = {
  small: "text-[2.15rem]",
  medium: "text-5xl",
  large: "text-6xl",
} as Record<SizeType, string>;

const citationFontSizeMap = {
  small: "text-base",
  medium: "text-lg",
  large: "text-xl",
} as Record<SizeType, string>;

const BlockQuote = ({
  hasQuoteMarks = false,
  size = "medium",
  quote: quotes,
  citation,
}: BlockquoteProps) => {
  const citations = useMemo<CitationType[]>(() => {
    if (!citation) return [];

    let citations: CitationType[] = [];
    if (typeof citation === "string") {
      citations = [
        {
          name: citation,
        },
      ];
    }

    if (typeof citation === "object" && Object.hasOwn(citation, "name")) {
      citations.push(citation as CitationType);
    }

    if (Array.isArray(citation)) {
      citations = citation;
    }

    return citations;
  }, [citation]);

  const Quote = (props: { quote: string }) => (
    <p
      className={twMerge(
        "h3 max-w-none heading-italic leading-none last-of-type:mb-3 ",
        sizeMap[size],
        quoteFontSizeMap[size]
      )}
    >
      {`${hasQuoteMarks ? '"' : ""}${props.quote}${hasQuoteMarks ? '"' : ""}`}
    </p>
  );

  return (
    <blockquote>
      {typeof quotes === "string" ? (
        <Quote quote={quotes} />
      ) : (
        quotes.map((quote, index) => <Quote key={index} quote={quote} />)
      )}
      <footer>
        {citations.map(({ name, link, cite }, index) => (
          <span key={index} className={twMerge("block opacity-65", citationFontSizeMap[size])}>
            <cite className="font-normal before:content-['-'] before:pr-1">
              {name}
              {link && (
                <>
                  ,{" "}
                  <a href={link} className="underline">
                    {cite}
                  </a>
                </>
              )}
            </cite>
          </span>
        ))}
      </footer>
    </blockquote>
  );
};

export default BlockQuote;
