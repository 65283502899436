import { useMemo, useState } from "react";

import { ArrowLeftIcon } from "@heroicons/react/20/solid";

import { AwardProps } from "components/Award";
import BlockQuote from "components/Blockquote";
import Button from "components/Button";
import AwardGrid from "components/cms/AwardGrid";
import Client from "components/cms/Client";
import Service, { ServiceKeyType } from "components/cms/Service";
import Stats, { Stat } from "components/cms/Stats";
import ContactForm from "components/ContactForm";
import ProjectGrid from "components/ProjectGrid";

export type SectionType =
  | "awards"
  | "contact_form"
  | "clients"
  | "block_quote"
  | "projects"
  | "services"
  | "stats"
  | "hero"
  | "body_content";

export interface Block {
  id: string;
  value: any;
  type: SectionType;
}

export interface BlockQuoteValue {
  quote: string;
  block_theme: string;
  attribution: string;
}

type ProjectValue = {
  slug: string;
};

type StatValue = {
  stat_number: string;
  body: string;
};

const Contact = () => {
  const [success, setSuccess] = useState(false);

  return (
    <>
      {!success ? (
        <ContactForm
          handleSuccess={() => {
            setSuccess(true);
          }}
        />
      ) : (
        <div className="mx-auto max-w-7xl px-4 md:px-6">
          <p className="pb-8 body-lg lg:max-w-5xl">
            Thank you for your interest in working with Fueled. A member of our New Projects team
            will be in touch as soon as possible. Be sure to check your spam folder if you
            haven&apos;t heard back from us.
          </p>
          <Button
            label="BACK TO CONTACT FORM"
            type="button"
            size="medium"
            iconPosition="left"
            colorScheme="dark"
            icon={ArrowLeftIcon}
            onClick={() => setSuccess(false)}
          />
        </div>
      )}
    </>
  );
};

const DynamicComponent = ({ section }: { section: Block }) => {
  const component = useMemo(() => {
    switch (section.type) {
      case "awards":
        const awards: AwardProps[] = (section.value.award as string[]).map((awardString) => {
          const [textAbove, title, textBelow] = awardString.replaceAll("_", " ").split("|");

          return { title, textAbove, textBelow };
        });
        return <AwardGrid awards={awards} />;
      case "contact_form":
        return <Contact />;
      case "clients":
        const clients = section.value.client as string[];
        return <Client companies={clients} />;
      case "block_quote":
        const props = section.value as BlockQuoteValue;
        return <BlockQuote quote={props.quote} citation={props.attribution} />;
      case "projects":
        const projects: ProjectValue[] = (section.value.project as string[]).map((project) => ({
          slug: project,
        }));
        return <ProjectGrid projects={projects} />;
      case "services":
        const services = section.value.service as ServiceKeyType[];
        return <Service service={services} />;
      case "stats":
        const stats: Stat[] = (section.value.stat as StatValue[]).map(
          (stat): Stat => ({
            label: stat.body,
            amount: parseInt(stat.stat_number),
          })
        );
        return <Stats stats={stats} />;
    }
  }, [section]);

  return <>{component}</>;
};

export default DynamicComponent;
