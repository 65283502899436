import React, { useMemo } from "react";

import { twJoin } from "tailwind-merge";

import { ButtonColorScheme, ButtonLink, ButtonSize } from "components/Button";

import { removeCmsRichTextWrapper } from "services/utils";

type LegacyButtonSize = "primary" | "secondary" | "tertiary";

export const convertLegacyButtonSize = {
  primary: "large",
  secondary: "medium",
  tertiary: "small",
} as const satisfies Record<LegacyButtonSize, ButtonSize>;

type Link = {
  link: string;
  size: LegacyButtonSize;
  text: string;
};

interface HeroProps {
  heading: string;
  cta?: Link;
  description?: string;
  ctaTheme: ButtonColorScheme;
}

const Hero = ({ cta, heading, description, ctaTheme }: HeroProps) => {
  const body = useMemo(() => removeCmsRichTextWrapper(description), [description]);
  return (
    <section aria-labelledby="heading" className="flex flex-col justify-center">
      <div className="mt-16 md:mt-20 py-12 md:py-20 lg:py-[7.5rem] xl:py-[8.125rem]">
        <h1 className={twJoin("super-h1", !body && cta?.link === "" && "mb-0")}>{heading}</h1>

        {!!body && (
          <div className="prose [&_*]:body-md" dangerouslySetInnerHTML={{ __html: body }} />
        )}

        {cta && cta.link && (
          <ButtonLink
            label={cta.text}
            href={cta.link}
            size={convertLegacyButtonSize[cta.size]}
            colorScheme={ctaTheme}
            target="_blank"
            rel="noopener noreferrer"
            className="mt-10"
          />
        )}
      </div>
    </section>
  );
};

export default Hero;
