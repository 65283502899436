import React from "react";

import { twJoin, twMerge } from "tailwind-merge";

import styles from "./award.module.scss";

// Returns the number closest to the provided number within the min–max range
const limitInt = (num: number, min: number, max: number) => {
  if (num < min) return min;
  else if (num > max) return max;

  return num;
};

const PRESET_TITLE_SCALES: Record<string, number> = {
  featured: 0.93,
  "#1 app": 1,
  "#2 app": 1,
  winner: 1,
  "best of 2016": 0.68,
};

export interface AwardProps {
  title: string;
  textAbove?: string;
  textBelow?: string;
  wrapperWidth?: number;
}

const Award = ({ title, textAbove, textBelow, wrapperWidth }: AwardProps) => {
  const awardTextClassNames = "flex justify-center uppercase w-full font-medium";
  const awardLeafClassNames =
    "absolute w-[19%] top-1/2 before:w-full before:block before:rounded-full before:content=[''] before:border-solid";
  const awardBoxRef = React.useRef<HTMLDivElement>(null);
  const titleRef = React.useRef<HTMLSpanElement>(null);
  const [awardWidth, setAwardWidth] = React.useState<number>(320);

  if (!title || title.length > 12) throw new Error("Award title cannot be more than 12 characters");

  React.useLayoutEffect(() => {
    function updateWidth() {
      if (awardBoxRef.current) {
        setAwardWidth(awardBoxRef.current?.clientWidth);
      }
    }
    window.addEventListener("resize", updateWidth, false);
    updateWidth();
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  const titleScale = React.useMemo<number>(() => {
    let titleScale = PRESET_TITLE_SCALES[title.toLowerCase()];

    if (!titleScale) {
      if (titleRef.current && awardBoxRef.current) {
        const width = awardBoxRef.current?.clientWidth;
        const titleWidth = titleRef.current?.clientWidth;
        const wrapperWidth = width * 0.715;
        Math.min(wrapperWidth / titleWidth, 1);
        titleScale = limitInt(Math.min(wrapperWidth / titleWidth, 1), 0, 1);
      }

      return 1;
    }

    return titleScale;
  }, [title]);

  return (
    <div
      ref={awardBoxRef}
      style={{ "--award-width": wrapperWidth || awardWidth } as React.CSSProperties}
      className={twJoin(styles["container"], "relative inline-flex w-full text-center")}
    >
      <div
        className="relative m-auto flex w-[71.5%] flex-col justify-center"
        style={
          {
            "--award-title-scale": titleScale,
          } as React.CSSProperties
        }
      >
        <span className={twJoin(awardLeafClassNames, styles["leaf"])} aria-hidden />
        {textAbove && (
          <span className={twMerge(awardTextClassNames, styles["top-text"], "top-full items-end")}>
            {textAbove}
          </span>
        )}

        <span
          className={twJoin(
            styles["title"],
            "relative left-1/2 w-full min-w-max whitespace-nowrap font-display font-normal uppercase leading-none"
          )}
        >
          {title}
        </span>
        {textBelow && (
          <span
            className={twMerge(
              awardTextClassNames,
              styles["bottom-text"],
              "bottom-full items-start"
            )}
          >
            {textBelow}
          </span>
        )}
        <span
          className={twJoin(awardLeafClassNames, styles["leaf"], styles["leaf--right"])}
          aria-hidden
        />
      </div>
    </div>
  );
};

export default Award;
